import { type SelectStatistics } from "~/schema/statistics";
import { type ConvertToAllString, type dictionary } from "./type";
import { type SelectModifiersList } from "~/schema/modifiers_list";

const modifiersList: ConvertToAllString<SelectModifiersList> = {
  name: "名前",
  modifiers: "補正項目",
  id: "ID",
};

const statistics: ConvertToAllString<SelectStatistics> = {
  id: "ID",
  rates: "評価",
  usageTimestamps: "使用記録",
  viewTimestamps: "閲覧記録",
  monsterId: "",
  crystalId: "",
  mainWeaponId: "",
  subWeaponId: "",
  bodyArmorId: "",
  additionalEquipmentId: "",
  specialEquipmentId: "",
  skillId: "",
  petId: "",
  consumableId: "",
  characterId: "",
  analyzerId: "",
};

const dictionary: dictionary = {
  ui: {
    searchPlaceholder: "ここで検索~",
    columnsHidden: "列を隠す",
    actions: {
      add: "追加",
      create: "作成",
      remove: "削除",
      update: "更新",
      open: "開く",
      upload: "アップロード",
      save: "保存",
      reset: "クリア",
      modify: "修正",
      cancel: "キャンセル",
      close: "閉じる",
      back: "戻る",
      filter: "フィルター",
    },
    nav: {
      home: "ホーム",
      monsters: "モンスター",
      skills: "スキル",
      equipments: "装備",
      crystals: "クリスタル",
      pets: "ペット",
      items: "消耗品",
      character: "キャラクター",
      analyzer: "コンボ分析",
    },
    errorPage: {
      tips: "あなたは知識の荒野にいるが、クリックして戻ります",
    },
    settings: {
      title: "設定",
      userInterface: {
        title: "外観",
        isAnimationEnabled: {
          title: "アニメーションを有効にする",
          description:
            "すべてのページの遷移とアニメーション効果の持続時間に影響します。このコンフィグで制御されていないアニメーションがある場合は、ご報告ください。",
        },
      },
      language: {
        title: "言語",
        selectedLanguage: {
          title: "言語を選択",
          description: "すべてのインターフェーステキストに影響しますが、データテキストは変更できません。",
          zhCN: "简体中文",
          zhTW: "繁体中文",
          enUS: "English",
          jaJP: "日本語",
        },
      },
      statusAndSync: {
        title: "状態と同期",
        restorePreviousStateOnStartup: {
          title: "起動時に前回の状態を復元",
          description: "まだ実装されていません。",
        },
        syncStateAcrossClients: {
          title: "すべてのクライアントの状態を同期",
          description:
            "この設定はユーザーがログインしている場合にのみ有効です。ログインしていない場合、クライアントには識別子がないため同期できません。",
        },
      },
      privacy: {
        title: "プライバシー",
        postVisibility: {
          title: "作品の可視性",
          description:
            "作品の可視性には以下が含まれます：キャラクター、モンスター、クリスタル、メイン武器、サブ武器、ボディアーマー、追加装備、特殊装備、ペット、スキル、消耗品、コンボ、アナライザー。",
          everyone: "全員に公開",
          friends: "フレンドのみに公開",
          onlyMe: "自分のみに公開",
        },
      },
      messages: {
        title: "メッセージ通知",
        notifyOnContentChange: {
          title: "以下の内容に変更があった場合に通知する",
          description: "まだ実装されていません。",
          notifyOnReferencedContentChange: "参照コンテンツに変更があった場合",
          notifyOnLike: "いいねを受け取った場合",
          notifyOnBookmark: "作品がブックマークされた場合",
        },
      },
      about: {
        title: "このアプリについて",
        description: {
          title: "説明",
          description: "まだ記述内容を決めていません。",
        },
        version: {
          title: "バージョン",
          description: "0.0.1-alpha",
        },
      },
    },
    index: {
      adventurer: "冒険者",
      goodMorning: "おはにゃ～ (=´ω｀=)",
      goodAfternoon: "こんにちは ヾ(=･ω･=)o",
      goodEvening: "こんばんは (。-ω-)zzz",
      nullSearchResultWarring: "関連コンテンツが見つかりません!!!∑(ﾟДﾟノ)ノ",
      nullSearchResultTips:
        "強くなる旅には困難が待ち受け、知識を求める道には障害物が散らばっています\nしかし、ここにはありません\n検索結果にないということは、存在しないということです",
    },
    monster: {
      pageTitle: "モンスター",
      table: {
        title: "モンスター",
        description: "ログイン後、自身でデータをアップロードできます。",
      },
      news: {
        title: "最新のアップデート",
      },
      augmented: "すべての星級データを表示",
      canNotModify: "システムによって生成され、修正不可",
      monsterDegreeOfDifficulty: {
        0: "ゼロ星",
        1: "一星",
        2: "二星",
        3: "三星",
        4: "四星",
      },
      monsterForm: {
        description:
          "固定ボスデータをアップロードする際は一星データを使用してください。システムが規則に従って他の星級データを自動的に計算します。",
      },
    },
    crystal: {
      pageTitle: "クリスタルテーブル",
      description: "開発中です。使用しないでください。",
      canNotModify: "システムによって生成され、修正不可",
      crystalForm: {
        description: "アララ",
      },
    },
    skill: {
      pageTitle: "スキル情報テーブル",
      description: "このページは開発中です。使用しないでください。",
    },
    analyze: {
      pageTitle: "プロセス計算機",
      description: "開発中です。使用しないでください。",
      modifiers: "補正項目",
      dialogData: {
        lv: "レベル",
        mainWeapon: {
          type: "メイン武器タイプ",
          baseAtk: "メイン武器基礎攻撃力",
          refinement: "メイン武器精錬値",
          stability: "メイン武器安定率",
        },
        subWeapon: {
          type: "サブ武器タイプ",
          baseAtk: "サブ武器基礎攻撃力",
          refinement: "サブ武器精錬値",
          stability: "サブ武器安定率",
        },
        bodyArmor: {
          type: "ボディアーマータイプ",
          baseDef: "ボディアーマー基礎防御力",
          refinement: "ボディアーマー精錬値",
        },
        str: "力",
        int: "知力",
        vit: "体力",
        agi: "敏捷",
        dex: "器用",
        luk: "運",
        cri: "クリティカル",
        tec: "テクニック",
        men: "異常耐性",
        pPie: "物理貫通",
        mPie: "魔法貫通",
        pStab: "物理安定",
        nDis: "近距離威力",
        fDis: "遠距離威力",
        crT: "魔法クリティカル変換率",
        cdT: "魔法クリティカルダメージ変換率",
        weaponPatkT: "武器攻撃変換率（物理）",
        weaponMatkT: "武器攻撃変換率（魔法）",
        uAtk: "抜刀攻撃",
        stro: {
          LIGHT: "",
          NO_ELEMENT: "",
          DARK: "",
          WATER: "",
          FIRE: "",
          EARTH: "",
          WIND: "",
        },
        total: "総ダメージ上昇",
        final: "最終ダメージ上昇",
        am: "行動速度",
        cm: "詠唱短縮",
        aggro: "ヘイト倍率",
        maxHp: "最大HP",
        maxMp: "最大MP",
        pCr: "物理クリティカル",
        pCd: "物理クリティカルダメージ",
        mainWeaponAtk: "メイン武器攻撃力",
        subWeaponAtk: "サブ武器攻撃力",
        weaponAtk: "武器攻撃力",
        pAtk: "物理攻撃",
        mAtk: "魔法攻撃",
        aspd: "攻撃速度",
        cspd: "詠唱速度",
        ampr: "攻撃MP回復",
        hp: "現在HP",
        mp: "現在MP",
        name: "名前",
        pDef: "物理防御",
        pRes: "物理耐性",
        mDef: "魔法防御",
        mRes: "魔法耐性",
        cRes: "クリティカル耐性",
        anticipate: "先読み",
        index: "インデックス",
        skillEffectType: "スキル効果タイプ",
        actionFixedDuration: "固定アクションフレーム",
        actionModifiableDuration: "加速可能アクションフレーム",
        skillActionFrames: "アクション総フレーム",
        chantingFixedDuration: "固定詠唱時間",
        chantingModifiableDuration: "加速可能詠唱時間",
        skillChantingFrames: "詠唱総時間",
        chargingFixedDuration: "固定チャージ時間",
        chargingModifiableDuration: "加速可能チャージ時間",
        skillChargingFrames: "チャージ総時間",
        skillDuration: "スキル総所要時間",
        skillStartupFrames: "スキル発動前隙",
        vMatk: "有効魔法攻撃力",
        vPatk: "有効物理攻撃力",
      },
      actualValue: "実際値",
      baseValue: "基本値",
      staticModifiers: "常時補正",
      dynamicModifiers: "一時補正",
    },
    character: {
      pageTitle: "キャラクターテーブル",
      description: "このページは開発中です。使用しないでください。",
    },
  },
  db: {
    enums: {
      MonsterType: {
        COMMON_BOSS: "固定ボス",
        COMMON_MINI_BOSS: "野外ボス",
        COMMON_MOBS: "雑魚",
        EVENT_BOSS: "イベント固定ボス",
        EVENT_MINI_BOSS: "イベント野外ボス",
        EVENT_MOBS: "イベント雑魚",
      },
      Element: {
        NO_ELEMENT: "無属性",
        DARK: "闇属性",
        EARTH: "地属性",
        FIRE: "火属性",
        LIGHT: "光属性",
        WATER: "水属性",
        WIND: "風属性",
      },
      SpecialAbiType: {
        NULL: "なし",
        LUK: "運",
        CRI: "クリティカル",
        TEC: "テクニック",
        MEN: "異常耐性",
      },
      MainWeaponType: {
        NO_WEAPON: "なし",
        ONE_HAND_SWORD: "片手剣",
        TWO_HANDS_SWORD: "両手剣",
        BOW: "弓",
        STAFF: "杖",
        MAGIC_DEVICE: "魔導具",
        KNUCKLE: "拳",
        HALBERD: "ハルバード",
        KATANA: "刀",
        BOWGUN: "ボウガン",
      },
      SubWeaponType: {
        NO_WEAPON: "なし",
        ONE_HAND_SWORD: "片手剣",
        MAGIC_DEVICE: "魔導具",
        KNUCKLE: "拳",
        KATANA: "刀",
        ARROW: "矢",
        DAGGER: "短剣",
        NINJUTSUSCROLL: "忍術の巻物",
        SHIELD: "盾",
      },
      BodyArmorType: {
        NORMAL: "通常",
        LIGHT: "軽装",
        HEAVY: "重装",
      },
      CrystalType: {
        GENERAL: "汎用クリスタル",
        WEAPONCRYSTAL: "武器クリスタル",
        BODYCRYSTAL: "防具クリスタル",
        ADDITIONALCRYSTAL: "追加クリスタル",
        SPECIALCRYSTAL: "特殊クリスタル",
      },
      SkillType: {
        ACTIVE_SKILL: "アクティブスキル",
        PASSIVE_SKILL: "パッシブスキル",
      },
      SkillTreeName: {
        BLADE: "剣系スキルツリー",
        MAGIC: "魔法スキルツリー",
        SHOT: "射撃スキルツリー",
        MARTIAL: "格闘スキルツリー",
        DUALSWORD: "双剣スキルツリー",
        HALBERD: "ハルバードスキルツリー",
        MONONOFU: "武士スキルツリー",
        CRUSHER: "クラッシャースキルツリー",
        SPRITE: "精霊スキルツリー",
      },
      UserRole: {
        USER: "一般ユーザー",
        ADMIN: "管理者",
      },
      YieldType: {
        ImmediateEffect: "即時効果（一度のみ適用）",
        PersistentEffect: "持続効果（削除されるまで有効）",
      },
      WeaponElementDependencyType: {
        TRUE: "継承",
        FALSE: "継承しない",
      },
      ComboType: {
        NULL: "未設定",
      },
      SkillExtraActionType: {
        None: "なし",
        Chanting: "詠唱",
        Charging: "チャージ",
      },
      CharacterType: {
        Tank: "タンク",
        Mage: "メイジ",
        Ranger: "レンジャー",
        Marksman: "マークスマン",
      },
    },
    models: {
      monster: {
        id: "ID",
        name: "名前",
        monsterType: "タイプ",
        baseLv: "レベル",
        experience: "経験値",
        address: "所在地",
        element: "属性",
        radius: "半径",
        maxhp: "最大HP",
        physicalDefense: "物理防御",
        physicalResistance: "物理耐性",
        magicalDefense: "魔法防御",
        magicalResistance: "魔法耐性",
        criticalResistance: "クリティカル耐性",
        avoidance: "回避値",
        dodge: "回避率",
        block: "ブロック率",
        normalAttackResistanceModifier: "通常攻撃抵抗修正",
        physicalAttackResistanceModifier: "物理攻撃抵抗修正",
        magicalAttackResistanceModifier: "魔法攻撃抵抗修正",
        difficultyOfTank: "難易度：タンク",
        difficultyOfMelee: "難易度：近接",
        difficultyOfRanged: "難易度：遠距離",
        possibilityOfRunningAround: "移動頻度",
        extraDetails: "追加詳細",
        dataSources: "データソース",
        createdByUserId: "作成者ID",
        updatedByUserId: "更新者ID",
        createdAt: "作成日時",
        updatedAt: "更新日時",
        statistics: statistics,
        statisticsId: "統計ID",
        imageId: "",
      },
      crystal: {
        id: "ID",
        modifiersList: modifiersList,
        name: "クリスタル名",
        crystalType: "クリスタルタイプ",
        front: "前置クリスタル数",
        modifiersListId: "補正リストID",
        extraDetails: "追加詳細",
        dataSources: "データソース",
        createdByUserId: "作成者ID",
        updatedByUserId: "更新者ID",
        createdAt: "作成日時",
        updatedAt: "更新日時",
        statistics: statistics,
        statisticsId: "統計ID",
      },
      skill: {
        id: "ID",
        name: "名前",
        skillType: "スキルタイプ",
        skillTreeName: "所属スキルツリー",
        weaponElementDependencyType: "武器属性継承タイプ",
        element: "スキル属性",
        skillEffect: "スキル効果",
        skillDescription: "スキル説明",
        extraDetails: "追加詳細",
        dataSources: "データソース",
        createdByUserId: "作成者ID",
        updatedByUserId: "更新者ID",
        createdAt: "作成日時",
        updatedAt: "更新日時",
        statistics: statistics,
        statisticsId: "統計ID",
      },
      user: {
        id: "アカウントID",
        name: "ユーザー名",
        email: "メールアドレス",
        emailVerified: "メール確認日時",
        image: "プロフィール画像",
        userRole: "ユーザー権限",
      },
      skillEffect: {
        id: "ID",
        condition: "発動条件",
        description: "条件説明",
        actionBaseDurationFormula: "基本アクション時間式（フレーム）",
        actionModifiableDurationFormula: "可変アクション時間式（フレーム）",
        skillExtraActionType: "追加アクション",
        chargingBaseDurationFormula: "基本チャージ時間式（秒）",
        chargingModifiableDurationFormula: "可変チャージ時間式（秒）",
        chantingBaseDurationFormula: "基本詠唱時間式（秒）",
        chantingModifiableDurationFormula: "可変詠唱時間式（秒）",
        skillStartupFramesFormula: "スキル発動前隙式（秒）",
        belongToskillId: "所属スキル",
        skillCost: "スキルコスト",
        skillYield: "スキル効果",
      },
      skillCost: {
        id: "ID",
        costFormula: "コスト計算式",
        skillEffectId: "所属スキル効果",
        name: "名前",
      },
      skillYield: {
        id: "ID",
        name: "名前",
        yieldType: "効果タイプ",
        mutationTimingFormula: "効果変化タイミング計算式",
        yieldFormula: "効果計算式",
        skillEffectId: "所属スキル効果",
      },
      character: {
        id: "ID",
        name: "名前",
        lv: "レベル",
        characterType: "キャラクタータイプ",
        baseStr: "基本筋力",
        baseInt: "基本知力",
        baseVit: "基本体力",
        baseAgi: "基本敏捷",
        baseDex: "基本器用",
        specialAbiType: "特殊能力タイプ",
        specialAbiValue: "特殊能力値",
        mainWeapon: {
          crystal: "クリスタル",
          id: "ID",
          name: "名前",
          mainWeaponType: "メイン武器タイプ",
          baseAtk: "基本攻撃力",
          refinement: "精錬値",
          stability: "安定率",
          element: "属性",
          modifiersList: modifiersList,
          modifiersListId: "補正リストID",
          extraDetails: "追加詳細",
          dataSources: "データソース",
          createdByUserId: "作成者ID",
          updatedByUserId: "更新者ID",
          createdAt: "作成日時",
          updatedAt: "更新日時",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        mainWeaponId: "メイン武器ID",
        subWeapon: {
          modifiersList: modifiersList,
          id: "ID",
          name: "名前",
          subWeaponType: "サブ武器タイプ",
          baseAtk: "基本攻撃力",
          refinement: "精錬値",
          stability: "安定率",
          element: "属性",
          modifiersListId: "",
          extraDetails: "追加詳細",
          dataSources: "データソース",
          createdByUserId: "作成者ID",
          updatedByUserId: "更新者ID",
          createdAt: "作成日時",
          updatedAt: "更新日時",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        subWeaponId: "",
        bodyArmor: {
          modifiersList: modifiersList,
          crystal: "クリスタル",
          id: "ID",
          name: "名前",
          bodyArmorType: "防具タイプ",
          refinement: "精錬値",
          baseDef: "基本防御力",
          modifiersListId: "",
          extraDetails: "追加詳細",
          dataSources: "データソース",
          createdByUserId: "作成者ID",
          updatedByUserId: "更新者ID",
          createdAt: "作成日時",
          updatedAt: "更新日時",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        bodyArmorId: "",
        additionalEquipment: {
          modifiersList: modifiersList,
          crystal: "クリスタル",
          id: "ID",
          name: "名前",
          refinement: "精錬値",
          modifiersListId: "",
          extraDetails: "追加詳細",
          dataSources: "データソース",
          createdByUserId: "作成者ID",
          updatedByUserId: "更新者ID",
          createdAt: "作成日時",
          updatedAt: "更新日時",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        additionalEquipmentId: "",
        specialEquipment: {
          modifiersList: modifiersList,
          crystal: "クリスタル",
          id: "ID",
          name: "名前",
          modifiersListId: "",
          extraDetails: "追加詳細",
          dataSources: "データソース",
          createdByUserId: "作成者ID",
          updatedByUserId: "更新者ID",
          createdAt: "作成日時",
          updatedAt: "更新日時",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        specialEquipmentId: "",
        fashion: modifiersList,
        fashionModifiersListId: "",
        cuisine: modifiersList,
        CuisineModifiersListId: "",
        consumableList: "消耗品リスト",
        skillList: "スキルリスト",
        combos: "コンボリスト",
        pet: {
          id: "ID",
          name: "名前",
          extraDetails: "追加詳細",
          dataSources: "データソース",
          createdByUserId: "作成者ID",
          updatedByUserId: "更新者ID",
          createdAt: "作成日時",
          updatedAt: "更新日時",
          statistics: statistics,
          statisticsId: "統計ID",
        },
        petId: "ペットID",
        modifiersList: modifiersList,
        modifiersListId: "追加補正ID",
        extraDetails: "追加詳細",
        createdByUserId: "作成者ID",
        updatedByUserId: "更新者ID",
        createdAt: "作成日時",
        updatedAt: "更新日時",
        statistics: statistics,
        statisticsId: "統計ID",
        imageId: "",
      },
    },
  },
};

export default dictionary;
